(function() {
    // Default Options for Bootstrap Select
    $('.selectpicker').selectpicker({
        showTick: true,
        width: '100%'
    });

    // Depending if chosen country is US, un-disable the state picker and zipcode maxlength changed to 5 
    $('#country-picker').on('changed.bs.select', function(e) {
        var status;
        var maxLength;
        e.preventDefault();

        if ($('#country-picker').val() === 'United States') {
            status = false;
            maxLength = 5;
        } else {
            status = true;
            maxLength = 10;
            $('#state-picker').val(null);
        }

        $('#state-picker').prop('disabled', status);
        $('#zipcode').prop('maxlength', maxLength);
        $('#state-picker').selectpicker('refresh');
    });

    // Keep the apply button disabled until the agreement has been checked
    $('#tos-checkbox').change(function(e) {
        var status;
        e.preventDefault();

        if (this.checked) {
            status = false;
        } else {
            status = true;
        }

        $('#apply-submit').prop('disabled', status);
    });
})()
